var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-width-container",
    [
      _c("vue-headful", { attrs: { title: _vm.appName + " - Logout" } }),
      _c(
        "gov-main-wrapper",
        [
          _c(
            "gov-grid-row",
            [
              _c(
                "gov-grid-column",
                { attrs: { width: "two-thirds" } },
                [
                  _c("gov-heading", { attrs: { size: "xl" } }, [
                    _vm._v("Log out")
                  ]),
                  _c("gov-body", { attrs: { size: "l" } }, [
                    _vm._v("You are currently logged out.")
                  ]),
                  _c("gov-body", { attrs: { size: "l" } }, [
                    _vm._v("Please login to access the system.")
                  ]),
                  _c("gov-button", { attrs: { href: _vm.loginUri } }, [
                    _vm._v("Login")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }